<template>
  <div>
    <div class="hidden-sm-and-down">
      <v-snackbar v-model="snackbar" multi-line color="primary" timeout="-1">
        <span style="font-weight: 800"
          >{{ $store.getters.stepper + 1 }}-
          {{ routeText[$store.getters.stepper] }}</span
        >
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            icon
            v-bind="attrs"
            @click="previousStep"
            :disabled="
              $store.getters.stepper > 0 ||
              routeText.length <= $store.getters.stepper
                ? false
                : true
            "
          >
            <v-icon> mdi-skip-previous </v-icon>
          </v-btn>
          <v-btn color="white" icon v-bind="attrs" @click="nextStep">
            <v-icon> mdi-skip-next </v-icon>
          </v-btn>
          <v-btn color="white" icon v-bind="attrs" @click="reset">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar v-model="showNoRouteSnackBar" :timeout="1000">
        Aradiginiz konuma rota bulunamadi
        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="showNoRouteSnackBar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div v-if="$vuetify.breakpoint.mobile === true">
      <div v-if="snackbar">
        <v-bottom-sheet
          v-model="snackbar"
          hide-overlay
          persistent
          elevation="0"
        >
          <v-card
            :class="[
              'rounded-tr-xl rounded-tl-xl',
              isIOSDevice ? 'ios-padding' : '',
            ]"
          >
            <div class="d-flex flex-row-reverse">
              <v-btn text @click="reset">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </div>

            <v-container class="bg-surface-variant">
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-subtitle-1 font-weight-bold text-left">
                    {{ $store.getters.stepper + 1 }}.
                    {{ routeText[$store.getters.stepper] }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div>
                    <v-btn
                      block
                      @click="previousStep"
                      elevation="0"
                      color="primary"
                      outlined
                      :disabled="
                        $store.getters.stepper > 0 ||
                        routeText.length <= $store.getters.stepper
                          ? false
                          : true
                      "
                      class="text-capitalize"
                    >
                      <v-icon> mdi-chevron-left </v-icon>
                      <!--  add transaltion  -->
                      {{ $t("back") }}
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div>
                    <v-btn
                      block
                      @click="nextStep"
                      elevation="0"
                      color="primary"
                      :disabled="
                        $store.getters.stepper < routeText.length - 1
                          ? false
                          : true
                      "
                      class="text-capitalize"
                    >
                      <!--  add transaltion  -->
                      {{ $t("next") }}
                      <v-icon> mdi-chevron-right </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-bottom-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import { postRemoveRouteEvent } from "../services/services";
import { isIOS } from "../utilities/deviceDetector";
import {
  correctSuffixForWord,
  capitalizeFirstLetter,
} from "../utilities/letterUtilities";

export default {
  props: ["routeInfo"],
  data() {
    return {
      snackbar: false,
      showNoRouteSnackBar: false,
      routeText: [],
      step: 0,
      routeArray: [],

      notLastStep: true,
      firstStep: true,
      isIOSDevice: false,
    };
  },

  methods: {
    nextStep() {
      //this methods moves user icon when user clicks next step

      if (this.$store.getters.stepper < this.routeText.length + 1) {
        this.notLastStep = true;
        if (this.routeArray[this.$store.getters.stepper].direction === "u") {
          this.$store.dispatch(
            "changeFloor",
            this.routeArray[this.$store.getters.stepper + 1].floor
          );
          this.$store.dispatch("routeFloor", 1);
          this.$store.dispatch("stepper", 1);
        } else if (
          this.routeArray[this.$store.getters.stepper].direction === "d"
        ) {
          this.$store.dispatch(
            "changeFloor",
            this.routeArray[this.$store.getters.stepper + 1].floor
          );
          this.$store.dispatch("routeFloor", 1);
          this.$store.dispatch("stepper", 1);
        } else {
          if (this.$store.getters.stepper < this.routeText.length - 1) {
            this.$store.dispatch("routeFloor", 1);
            this.$store.dispatch("stepper", 1);
          }
          if (this.$store.getters.stepper === this.routeText.length - 1) {
            this.notLastStep = false;
            this.$store.dispatch("postReachDestination");
          }
        }
      } else {
        this.notLastStep = false;
        this.$store.dispatch("postReachDestination");
      }
    },
    previousStep() {
      //this methods moves user icon when user clicks next step

      if (this.$store.getters.stepper <= this.routeText.length + 1) {
        this.firstStep = true;

        if (
          this.routeArray[this.$store.getters.stepper - 1]?.direction === "u"
        ) {
          this.$store.dispatch(
            "changeFloor",
            this.routeArray[this.$store.getters.stepper - 1].floor
          );
          this.$store.dispatch("routeFloor", -1);
          this.$store.dispatch("stepper", -1);
        } else if (
          this.routeArray[this.$store.getters.stepper - 1]?.direction === "d"
        ) {
          this.$store.dispatch(
            "changeFloor",
            this.routeArray[this.$store.getters.stepper - 1].floor
          );
          this.$store.dispatch("routeFloor", -1);
          this.$store.dispatch("stepper", -1);
        } else {
          this.$store.dispatch("stepper", -1);

          if (this.$store.getters.stepper <= 0) {
            this.firstStep = false;
          }
        }
      } else {
        this.firstStep = false;
      }
    },
    reset() {
      //Resets all state
      const { endPoint, startPoint, eta } =
        this.$store.getters.startPointEndPointInfo;
      const route = this.$store.getters.routeInfo;
      postRemoveRouteEvent({ endPoint, startPoint, route, eta });

      this.snackbar = false;
      this.$store.dispatch("reset");
    },
    renderChangeDirectionText(
      directionTranslate,
      currentStepInfo,
      nextStepInfo,
      hasFilterPoints
    ) {
      const language = localStorage.getItem("language");
      if (hasFilterPoints) {
        if (language === "tr")
          return `${correctSuffixForWord(
            currentStepInfo.title
          )} ${directionTranslate}  ve ${
            currentStepInfo.distance
          } metre ilerleyin`;
        else
          return `${this.$t("near")} ${currentStepInfo.title}
                ${this.$t("and")} ${this.$t("goStraight")} ${this.$t("for")} ${
            currentStepInfo.distance
          } ${this.$t("meter")}`;
      } else {
        return language !== "tr"
          ? `${directionTranslate}
            ${this.$t("and")} ${this.$t("goStraight")}
            ${this.$t("for")}
            ${currentStepInfo.distance}
            ${this.$t("meter")}`
          : `${directionTranslate} ve ${currentStepInfo.distance} metre ilerleyin.`;
      }
    },
    renderRouteSnackBarText(hasFilterPoints, currentStepInfo, nextStepInfo) {
      const language = localStorage.getItem("language");
      const routeSummary = this.$store.getters.place.data.properties
        .routeSummary
        ? this.$store.getters.place.data.properties.routeSummary
        : "floorlevel";

      if (currentStepInfo.direction === "s") {
        if (hasFilterPoints === true) {
          language !== "tr"
            ? this.routeText.push(
                capitalizeFirstLetter(
                  `${this.$t("near")}
                 ${currentStepInfo.title}
                ${this.$t("and")}
                ${this.$t("goStraight")}
                ${this.$t("for")}
                ${currentStepInfo.distance}
                ${this.$t("meter")}.`
                )
              )
            : this.routeText.push(
                capitalizeFirstLetter(
                  `${correctSuffixForWord(currentStepInfo.title)} geçin ve ${
                    currentStepInfo.distance
                  } metre ilerleyin.`
                )
              );
        } else {
          this.routeText.push(
            capitalizeFirstLetter(
              this.$t("goStraight") +
                " ( " +
                currentStepInfo.distance +
                this.$t("meter") +
                " )"
            )
          );
        }
        //if turn left
      } else if (currentStepInfo.direction === "l") {
        this.routeText.push(
          capitalizeFirstLetter(
            this.renderChangeDirectionText(
              this.$t("turnLeft"),
              currentStepInfo,
              nextStepInfo,
              hasFilterPoints
            )
          )
        );
      } else if (currentStepInfo.direction === "r") {
        this.routeText.push(
          capitalizeFirstLetter(
            this.renderChangeDirectionText(
              this.$t("turnRight"),
              currentStepInfo,
              nextStepInfo,
              hasFilterPoints
            )
          )
        );
      } else if (currentStepInfo.direction === "sl") {
        this.routeText.push(
          capitalizeFirstLetter(
            this.renderChangeDirectionText(
              this.$t("turnSlightLeft"),
              currentStepInfo,
              nextStepInfo,
              hasFilterPoints
            )
          )
        );
      } else if (currentStepInfo.direction === "sr") {
        this.routeText.push(
          capitalizeFirstLetter(
            this.renderChangeDirectionText(
              this.$t("turnSlightRight"),
              currentStepInfo,
              nextStepInfo,
              hasFilterPoints
            )
          )
        );

        //if go right
      } else if (currentStepInfo.direction === "u") {
        const floorData =
          routeSummary === "floorname"
            ? this.$store.getters.floors[nextStepInfo?.floor].title
            : nextStepInfo?.floor;

        //if next move is up
        this.routeText.push(
          capitalizeFirstLetter(
            this.$t("goUpStairs") + " ( " + floorData + " )"
          )
        );
      } else if (currentStepInfo.direction === "d") {
        const floorData =
          routeSummary === "floorname"
            ? this.$store.getters.floors[nextStepInfo.floor].title
            : nextStepInfo.floor;

        this.routeText.push(
          capitalizeFirstLetter(`${this.$t("goDownStairs")} (${floorData})`)
        );
      } else if (currentStepInfo.direction === "f") {
        this.routeText.push(capitalizeFirstLetter(this.$t("destinationPoint")));
      }
    },
  },
  mounted() {
    this.isIOSDevice = isIOS();

    if (this.routeInfo.length > 1) {
      this.snackbar = true;

      this.routeArray = this.$store.getters.routeInfo;

      for (let i = 0; i < this.$store.getters.routeInfo.length; i++) {
        const hasFilterPoints =
          this.$store.getters.routeInfo[i].filters.pointType.includes(
            "checkpoint"
          ) ||
          this.$store.getters.routeInfo[i].filters.pointType.includes("info") ||
          (this.$store.getters.routeInfo[i]?.restrictionWarning !== undefined &&
            this.$store.getters.routeInfo[i]?.restrictionWarning !== "");

        this.renderRouteSnackBarText(
          hasFilterPoints,
          this.$store.getters.routeInfo[i],
          this.$store.getters.routeInfo[i + 1]
        );
      }
    } else {
      this.showNoRouteSnackBar = false;
    }
  },
};
</script>

<style scoped>
.ios-padding {
  padding-bottom: 20px;
}
</style>
